@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}

body {
  background-size: cover;
  height: 100%;
  background-attachment: fixed;
  background-position: top;
}

body {
  /* SCSS error is okay here. */
  background-image: url(/static/media/background.58abc6b1.jpg);
}

header {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  header {
    width: 90%;
    max-width: 1200px;
  }
}
header .img-header {
  width: 100%;
}
@media (min-width: 768px) {
  header .img-header {
    width: 70%;
    max-width: 400px;
  }
}

.cookies-breakout {
  color: red;
  opacity: 0.5;
}

.content {
  margin: 0 auto;
  background: white;
  min-height: 600px;
  border-radius: 5px;
  width: 90%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media (min-width: 768px) {
  .content {
    width: 90%;
    max-width: 1200px;
  }
}
.content a.back {
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  background: #DE2228;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
}
@media (min-width: 768px) {
  .content a.back {
    font-size: 1.1rem;
  }
}
.content .interaction-container {
  display: flex;
  flex-direction: row;
}
.content .interaction-container .progress-container {
  padding: 10px 15px;
  margin: 10px 10px;
  border: 1px solid #DE2228;
}
.content .interaction-container .progress-container h1 {
  color: #DE2228;
}
.content .interaction-container .rarity-container {
  padding: 10px 15px;
  margin: 10px 10px;
  border: 1px solid #DE2228;
}
.content .interaction-container .rarity-container h3 {
  margin-bottom: 5px;
}
.content .interaction-container .rarity-container div {
  display: flex;
  flex-direction: column;
}
.content .interaction-container .rarity-container div div {
  display: flex;
  flex-direction: column;
}
.content .interaction-container .rarity-container div div h4 {
  font-size: 1.2rem;
  color: #DE2228;
}
.content .interaction-container .rarity-container div div p {
  font-size: 1.1rem;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .content .interaction-container .rarity-container div div p {
    font-size: 0.9rem;
    margin: 0 15px;
  }
}
@media (min-width: 768px) {
  .content .interaction-container .rarity-container div {
    flex-direction: row;
  }
}
.content .addflgs {
  margin-bottom: 25px;
}
.content .addflgs a {
  color: #DE2228;
}
.content .filters {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .content .filters {
    flex-direction: row;
    justify-content: center;
  }
}
.content .filters .filter {
  padding: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .content .filters .filter h4 {
    margin-right: 5px;
  }
}
.content .filters .filter button {
  background: #eee;
  border-radius: 5px;
  text-transform: capitalize;
  border: 1px solid #ddd;
  padding: 5px 20px;
}
@media (min-width: 768px) {
  .content .filters .filter button {
    margin: 3px;
    padding: 10px 15px;
    cursor: pointer;
  }
}
.content .filters .filter button.active {
  background: #DE2228;
  font-weight: 800;
  color: white;
}
@media (min-width: 768px) {
  .content .intro {
    padding: 25px 50px 0;
  }
}
.content .intro h3 {
  font-size: 1.2rem;
  margin: 5px 0;
}
.content .intro a {
  color: #DE2228;
}
.content .intro p {
  margin: 10px 0;
}
.content .intro .bold {
  font-weight: 800;
  color: #DE2228;
}
.content .links {
  margin-top: 10px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .content .links {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 30px;
  }
}
.content .links .link {
  padding: 10px 5px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  border: #eee solid 1px;
}
@media (min-width: 768px) {
  .content .links .link {
    align-items: center;
    padding: 5px 10px;
  }
}
.content .links .link .link-as {
  margin: 5px;
  display: flex;
}
.content .links .link .link-as a {
  margin: 2px;
  border-radius: 5px;
  padding: 10px;
  background: #DE2228;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  width: 100%;
}
@media (min-width: 768px) {
  .content .links .link .link-as a {
    font-size: 1.1rem;
    width: 100%;
  }
}
.content hr {
  border: 1px solid #861418;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 15px;
}
.content .password-container {
  padding: 10px;
}
.content .password-container form {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.content .password-container form label {
  font-size: 1.2rem;
}
.content .password-container form .field-password {
  padding: 10px;
  margin: 10px;
  font-size: 2rem;
  text-align: center;
  width: 90%;
}
.content .password-container form .field-submit {
  color: white;
  background: #DE2228;
  padding: 10px 5px;
  font-size: 1.2rem;
  border: 0;
  width: 90%;
}
.content .dice-container {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.content .dice-container .design-group {
  display: flex;
}
.content .dice-container .dice-card {
  margin: 5px;
  border: 2px solid #eee;
  padding: 5px;
  position: relative;
  max-width: 100%;
}
.content .dice-container .dice-card.checked {
  border: #DE2228 2px solid;
}
.content .dice-container .dice-card input.tracker {
  position: absolute;
  z-index: 1000;
  top: 7px;
  left: 5px;
  width: 50px;
  padding: 5px 0;
  border: 3px solid #DE2228;
  border-radius: 5px;
  text-align: center;
  font-size: 1.3rem;
}
.content .dice-container .dice-card input.tracker:invalid {
  background: #DE2228;
}
.content .dice-container .dice-card img {
  transition: 0.2s;
  width: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
}
@media (min-width: 768px) {
  .content .dice-container .dice-card img {
    width: 150px;
  }
}
.content .dice-container .dice-card img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.content .dice-container .dice-card h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 0.7rem;
}
.content .dice-container .dice-card h6 {
  color: #999;
  font-size: 0.7rem;
  font-weight: 300;
  margin: 5px;
}
.content .flgs-container {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) {
  .content .flgs-container {
    grid-template-columns: 1fr 1fr;
  }
}
.content .flgs-container .flgs-card {
  max-width: 400px;
  padding: 5px;
  border: 1px solid #eee;
}
@media (min-width: 768px) {
  .content .flgs-container .flgs-card {
    padding: 10px;
  }
}
.content .flgs-container .flgs-card a {
  color: black;
  text-decoration: none;
}
.content .flgs-container .flgs-card a.store_name {
  color: #DE2228;
}
.content .flgs-container .flgs-card a:hover {
  text-decoration: underline;
}

.breakout {
  background: black;
  width: 100%;
  text-align: center;
  border: 1px solid #DE2228;
  padding: 3px;
}
@media (min-width: 768px) {
  .breakout {
    position: absolute;
    top: 0;
    width: auto;
    padding: 10px 15px;
    opacity: 0.8;
    transition: 0.2s opacity;
  }
  .breakout.breakout-right {
    right: 0;
    border-radius: 0 0 0 10px;
  }
  .breakout.breakout-left {
    left: 0;
    border-radius: 0 0 10px 0;
  }
}
.breakout a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 800;
}
@media (min-width: 768px) {
  .breakout a {
    font-size: 2rem;
  }
}
.breakout:hover {
  opacity: 1;
}

footer {
  color: white;
  text-align: center;
  margin-top: 30px;
  padding: 10px;
}
footer a {
  color: white;
}

.message {
  margin: 25px;
}

/*# sourceMappingURL=App.css.map */

